import { rollbarConfig } from "lib/rollbarConfig";
import React, { useCallback } from "react";
import Rollbar from "rollbar";
import { styled } from "styled-components";

const rollbar = new Rollbar(rollbarConfig);

const NoStyleA = styled.a`
  text-decoration: none;
`;

export interface ChatLinkProps {
  children: React.ReactNode;
}

export default (props: ChatLinkProps) => {
  const handleOnClick = useCallback(() => {
    // @ts-ignore
    if (typeof $zopim !== "undefined" && $zopim?.livechat?.window?.show) {
      // @ts-ignore
      $zopim.livechat.window.show();
    }
    // @ts-ignore
    if (!$zoipim) {
      rollbar.info(`Zopim chatbox loading fails`);
    }
  }, []);

  return (
    <NoStyleA onClick={handleOnClick} {...props}>
      {props.children}
    </NoStyleA>
  );
};
