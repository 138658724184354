/* eslint-disable @typescript-eslint/no-shadow */

import {
  Checkbox,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ROUTES } from "domain/configuration";
import {
  checkSuccessfulAuthorisationAction,
  completeRegistrationAction,
  createWindcaveTransactionAction,
  updateFlickSignUpAction,
} from "domain/core/actions";
import {
  setCurrentFormStep,
  setFlickSignUpStateComplete,
  setLeadSource,
  setPromoCode,
  setUserChecks,
  setUserPayment,
} from "domain/core/reducers";
import {
  BankAccountType,
  LeadSourceOptions,
  PaymentType,
  PaymentTypes,
  ProductName,
} from "domain/core/types";
import { usePrevious } from "lib/hooks";
import { REQUEST_STATUS } from "lib/types";
import { sendAnalyticsPageView } from "lib/util";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { PatternFormat } from "react-number-format";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import { css, styled } from "styled-components";
import { BackButton } from "ui/components/BackButton";
import Button from "ui/components/Button";
import ChatLink from "ui/components/ChatLink";
import { LoadingWrapper } from "ui/components/LoadingWrapper";
import { SingleSelectRadio } from "ui/components/SingleSelectRadio";
import { FormWrapper, RowLayout } from "ui/components/StyledComponents";
import CreditCardInput from "ui/compounds/CreditCardInput";
import theme, { PADDING } from "ui/theme";

const TextInputShort = styled(TextField)`
  max-width: 15rem;
`;

const ErrorLink = styled(Typography)`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`;

const SFormControlLabel = styled(FormControlLabel)`
  align-items: start;
`;

const indentStyle = css`
  margin-left: ${PADDING.medium};

  @media (min-width: ${theme.breakpoints.values.sm}px) {
    margin-left: ${PADDING.xLarge};
  }
`;

const CollapseWrapped = styled(Collapse)`
  ${indentStyle}
`;

const Spacer = styled.div`
  margin-top: ${PADDING.small};
`;

const paymentTypeToBankAccountTypeMap = {
  [PaymentTypes.DIRECT_DEBIT_JOINT]: "joint_signatory",
  [PaymentTypes.DIRECT_DEBIT_PERSONAL]: "personal",
};

interface State {
  paymentType?: PaymentTypes;
  accName: string;
  accNumber: string;
  statementParticulars: string;
  promoCode?: string;
  leadSource?: LeadSourceOptions;
  statementCode: string;
  authorityCheck: boolean;
  directDebitTnCCheck: boolean;
  creditCheck: boolean;
  zElecTnCCheck: boolean;
  marketingAccepted: boolean;
  updateViaSMSCheck: boolean;
  paymentOptions: string[];
  errAccName?: string;
  errAccNumber?: string;
  errStatementParticulars?: string;
  errStatementCode?: string;
  errTnCs?: boolean;
  errCreditCard?: string;
  errZElecTnCs?: boolean;
  errCreditCheck?: boolean;
  errSubmission?: string;
  errPaymentType?: string;
  errPromoCode?: string;
}

export const Payment: React.FC = () => {
  window.onbeforeunload = () => null;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    selectedProduct,
    completeRegistrationRequestStatus: registrationStatus,
    flickAccountError,
    leadSource,
    promoCode,
    updateFlickSignUpRequestStatus: signUpUpdateStatus,
    user: { payment: paymentDetails },
    windcaveAuthorisationRequestStatus,
  } = useAppSelector((store) => store.coreData);

  const prevStatus = usePrevious(registrationStatus);
  const prevSignUpUpdateStatus = usePrevious(signUpUpdateStatus);
  const [formComplete, setFormComplete] = useState<boolean>(false);

  useEffect(() => {
    sendAnalyticsPageView("Payment details");
  }, []);

  useEffect(() => {
    if (
      prevStatus === REQUEST_STATUS.Pending &&
      registrationStatus === REQUEST_STATUS.Fulfilled
    ) {
      dispatch(setFlickSignUpStateComplete());
      dispatch(updateFlickSignUpAction.request());
      setFormComplete(true);
    }
    if (
      prevStatus === REQUEST_STATUS.Pending &&
      registrationStatus === REQUEST_STATUS.Failed
    ) {
      setState((state) => ({
        ...state,
        errSubmission:
          flickAccountError ??
          "Something went wrong, please try again or click here to contact support.",
      }));
    }
  }, [prevStatus, registrationStatus]);

  useEffect(() => {
    if (formComplete) {
      if (
        prevSignUpUpdateStatus === REQUEST_STATUS.Pending &&
        signUpUpdateStatus === REQUEST_STATUS.Fulfilled
      ) {
        dispatch(setCurrentFormStep("/success"));
        navigate(ROUTES.success);
      }
      if (
        prevSignUpUpdateStatus === REQUEST_STATUS.Pending &&
        signUpUpdateStatus === REQUEST_STATUS.Failed
      ) {
        if (flickAccountError.includes("promo code")) {
          setState((state) => ({
            ...state,
            errPromoCode: flickAccountError ?? "This promo code is invalid.",
          }));
        } else {
          setState((state) => ({
            ...state,
            errSubmission:
              flickAccountError ??
              "Something went wrong, please try again or click here to contact support.",
          }));
        }
      }
    }
  }, [prevSignUpUpdateStatus, signUpUpdateStatus]);

  useEffect(() => {
    const query = window.location?.search;
    if (query) {
      setState((state) => ({
        ...state,
        paymentType: PaymentTypes.CREDIT_CARD,
      }));
      dispatch(checkSuccessfulAuthorisationAction.request());
    }
  }, []);

  const [state, setState] = useState<State>({
    paymentType:
      paymentDetails.paymentType === "credit_card"
        ? PaymentTypes.CREDIT_CARD
        : paymentDetails.bankAccountType === "personal"
          ? PaymentTypes.DIRECT_DEBIT_PERSONAL
          : (PaymentTypes.DIRECT_DEBIT_JOINT ?? undefined),
    accName: paymentDetails.directDebit?.accountName ?? "",
    accNumber: paymentDetails.directDebit?.accountNumber ?? "",
    statementParticulars: paymentDetails.directDebit?.pariculars ?? "",
    statementCode: paymentDetails.directDebit?.code ?? "",
    promoCode: promoCode ?? undefined,
    leadSource: leadSource ?? undefined,
    authorityCheck: paymentDetails.directDebit?.authorityConfirmed ?? false,
    directDebitTnCCheck: paymentDetails.directDebit?.tncsAgreed ?? false,
    creditCheck: paymentDetails.creditCheckAccecpted ?? false,
    zElecTnCCheck: false,
    marketingAccepted: true,
    updateViaSMSCheck: true,
    paymentOptions: [
      PaymentTypes.CREDIT_CARD,
      PaymentTypes.DIRECT_DEBIT_PERSONAL,
      PaymentTypes.DIRECT_DEBIT_JOINT,
    ],
  });

  const validateForm = useCallback(() => {
    let error = false;
    const accountNameRegex = /^[a-z0-9@?*#:{}()\-&.\\\s']+$/i;

    if (!state.paymentType) {
      error = true;
      setState((state) => ({
        ...state,
        errPaymentType: "Oops, please select one.",
      }));
    }
    if (!state.creditCheck) {
      error = true;
      setState((state) => ({ ...state, errCreditCheck: true }));
    }
    if (!state.zElecTnCCheck) {
      error = true;
      setState((state) => ({ ...state, errZElecTnCs: true }));
    }

    switch (state.paymentType) {
      case PaymentTypes.CREDIT_CARD:
        if (windcaveAuthorisationRequestStatus !== REQUEST_STATUS.Fulfilled) {
          error = true;
          setState((state) => ({
            ...state,
            errCreditCard: "Please add a credit card",
          }));
        }
        return !error;
      case PaymentTypes.DIRECT_DEBIT_JOINT:
        // No validation needed
        return !error;
      case PaymentTypes.DIRECT_DEBIT_PERSONAL:
        if (state.accName === "") {
          error = true;
          setState((state) => ({
            ...state,
            errAccName: "Please enter your account name.",
          }));
        }
        if (state.accName.match(accountNameRegex) == null) {
          error = true;
          setState((state) => ({
            ...state,
            errAccName:
              "You can only use letters, numbers, full stops, spaces or, @ ? * # : { } ( ) - & ' \\ in Bank account name.",
          }));
        }
        if (state.accNumber === "") {
          error = true;
          setState((state) => ({
            ...state,
            errAccNumber: "Please enter your account number.",
          }));
        }
        if (
          state.statementParticulars &&
          alphaNumericValidationFailure(state.statementParticulars)
        ) {
          error = true;
          setState((state) => ({
            ...state,
            errStatementParticulars: "You can only use letters or numbers.",
          }));
        }
        if (
          state.statementCode &&
          alphaNumericValidationFailure(state.statementCode)
        ) {
          error = true;
          setState((state) => ({
            ...state,
            errStatementCode: "You can only use letters or numbers.",
          }));
        }
        if (!state.directDebitTnCCheck) {
          error = true;
          setState((state) => ({ ...state, errTnCs: true }));
        }
        // Validate direct debit fields
        return !error;
      default:
        return false;
    }
  }, [state, windcaveAuthorisationRequestStatus]);

  const alphaNumericValidationFailure = (text: string) => {
    const match = text.match(/^[a-z0-9\s]+$/i);
    return match === null;
  };

  const handleNavigationBack = useCallback(() => {
    const paymentType: PaymentType =
      state.paymentType === PaymentTypes.CREDIT_CARD
        ? PaymentTypes.CREDIT_CARD
        : "direct_debit";

    const bankAccountType: BankAccountType | undefined =
      paymentTypeToBankAccountTypeMap[state.paymentType] ?? undefined;

    if (state.promoCode) {
      dispatch(setPromoCode(state.promoCode));
    }

    if (state.leadSource !== undefined) {
      dispatch(setLeadSource(state.leadSource));
    }

    const directDebit = {
      accountName: state.accName,
      accountNumber: state.accNumber.replace(/-/g, "").trim(),
      pariculars: state.statementParticulars,
      code: state.statementCode,
      authorityConfirmed: state.authorityCheck,
      tncsAgreed: state.directDebitTnCCheck,
    };

    // persist checks and payment
    dispatch(
      setUserChecks({
        marketingAccepted: state.marketingAccepted,
        smsSwitchNotificationsAccepted: state.updateViaSMSCheck,
        termsAccepted: state.zElecTnCCheck,
      }),
    );

    dispatch(
      setUserPayment({
        bankAccountType: bankAccountType,
        paymentType: paymentType,
        creditCheckAccecpted: state.creditCheck,
        directDebit: directDebit,
      }),
    );
  }, [state]);

  const handleSendApplicationPress = useCallback(() => {
    if (!validateForm()) return;
    const paymentType: PaymentType =
      state.paymentType === PaymentTypes.CREDIT_CARD
        ? PaymentTypes.CREDIT_CARD
        : "direct_debit";

    let bankAccountType: BankAccountType | undefined;
    if (state.paymentType === PaymentTypes.DIRECT_DEBIT_JOINT) {
      bankAccountType = "joint_signatory";
    }

    dispatch(setPromoCode(state.promoCode));

    if (state.leadSource !== undefined) {
      dispatch(setLeadSource(state.leadSource));
    }

    let directDebit;
    if (state.paymentType === PaymentTypes.DIRECT_DEBIT_PERSONAL) {
      bankAccountType = "personal";
      directDebit = {
        accountName: state.accName,
        accountNumber: state.accNumber.replace(/-/g, "").trim(),
        pariculars: state.statementParticulars,
        code: state.statementCode,
        authorityConfirmed: state.authorityCheck,
        tncsAgreed: state.directDebitTnCCheck,
      };
    }

    // persist checks and payment
    dispatch(
      setUserChecks({
        marketingAccepted: state.marketingAccepted,
        smsSwitchNotificationsAccepted: state.updateViaSMSCheck,
        termsAccepted: state.zElecTnCCheck,
      }),
    );

    dispatch(
      setUserPayment({
        bankAccountType: bankAccountType,
        paymentType: paymentType,
        creditCheckAccecpted: state.creditCheck,
        directDebit: directDebit,
      }),
    );

    if (selectedProduct === ProductName.Z_ELECTRIC_REWARDS) {
      dispatch(completeRegistrationAction.request());
      return;
    }

    dispatch(setFlickSignUpStateComplete());
    dispatch(updateFlickSignUpAction.request());
    setFormComplete(true);
  }, [validateForm, state, selectedProduct, signUpUpdateStatus]);

  const handlePaymentChange = (value: PaymentTypes) => {
    const paymentType = value;
    setState((state) => ({
      ...state,
      paymentType,
      errPaymentType: "",
    }));
  };

  // Credit Card
  const handleAddCreditCard = useCallback(() => {
    handleNavigationBack();
    dispatch(createWindcaveTransactionAction.request());
  }, []);

  // Personal Direct Debit
  const handleAccountNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setState((state) => ({ ...state, accName: value, errAccName: "" }));
    },
    [],
  );

  const handleAccountNumberChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setState((state) => ({ ...state, accNumber: value, errAccNumber: "" }));
    },
    [],
  );

  const handleStatementParticularsChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setState((state) => ({
        ...state,
        statementParticulars: value,
        errStatementParticulars: "",
      }));
    },
    [],
  );

  const handleStatementCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setState((state) => ({
        ...state,
        statementCode: value,
        errStatementCode: "",
      }));
    },
    [],
  );

  const handleAuthorityAndDirectDebitTnCCheck = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setState((state) => ({
        ...state,
        directDebitTnCCheck: checked,
        authorityCheck: checked,
        errTnCs: false,
      }));
    },
    [],
  );

  // Promo code, legal checks and terms
  const handlePromoCodeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setState((state) => ({
        ...state,
        promoCode: Boolean(value) ? value : undefined,
        errPromoCode: undefined,
      }));
    },
    [],
  );

  const handleLeadSourceChange = (leadSourceValue: string) => {
    if (
      (Object.values(LeadSourceOptions) as string[]).includes(leadSourceValue)
    ) {
      setState((state) => ({
        ...state,
        leadSource: leadSourceValue as LeadSourceOptions,
      }));
    }
  };

  const handleMarketingCommsChange = (event: any) => {
    const { checked } = event.target;
    setState((state) => ({
      ...state,
      marketingAccepted: checked,
    }));
  };

  const handleCreditCheckChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setState((state) => ({
        ...state,
        creditCheck: checked,
        errCreditCheck: false,
      }));
    },
    [],
  );

  const handleZElecTnCChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setState((state) => ({
        ...state,
        zElecTnCCheck: checked,
        errZElecTnCs: false,
      }));
    },
    [],
  );

  const creditCardCollapse = (
    <CollapseWrapped in={state.paymentType === PaymentTypes.CREDIT_CARD}>
      <CreditCardInput
        onCreditCardPress={handleAddCreditCard}
        cardAdded={
          windcaveAuthorisationRequestStatus === REQUEST_STATUS.Fulfilled
        }
        error={state.errCreditCard}
        billCycle={
          selectedProduct === ProductName.Z_ELECTRIC_REWARDS
            ? "fortnightly"
            : "monthly"
        }
      />
    </CollapseWrapped>
  );

  const directDebitCollapse = (
    <CollapseWrapped
      in={state.paymentType === PaymentTypes.DIRECT_DEBIT_PERSONAL}
    >
      <FormWrapper>
        <TextField
          id="direct-deb-account-name"
          label="Bank account name"
          value={state.accName}
          onChange={handleAccountNameChange}
          error={!!state.errAccName}
          inputProps={{ maxLength: 255 }}
          helperText={!state.errAccName ? undefined : state.errAccName}
        />
        <PatternFormat
          customInput={TextField}
          format="##-####-#######-###"
          mask="  "
          id="direct-deb-account-number"
          label="Bank account number"
          value={state.accNumber}
          onChange={handleAccountNumberChange}
          placeholder="e.g. 12-3456-7890123-000"
          error={!!state.errAccNumber}
          helperText={!state.errAccNumber ? undefined : state.errAccNumber}
          valueIsNumericString
        />
        <RowLayout>
          <TextInputShort
            id="direct-deb-statement-particulars"
            label="Particulars"
            inputProps={{ maxLength: 12 }}
            value={state.statementParticulars}
            onChange={handleStatementParticularsChange}
            error={!!state.errStatementParticulars}
            helperText={
              !state.errStatementParticulars
                ? "e.g. Power"
                : state.errStatementParticulars
            }
          />
        </RowLayout>
        <RowLayout>
          <TextInputShort
            id="direct-deb-statement-code"
            label="Code"
            inputProps={{ maxLength: 12 }}
            value={state.statementCode}
            onChange={handleStatementCodeChange}
            error={!!state.errStatementCode}
            helperText={
              !state.errStatementCode ? "e.g Power" : state.errStatementCode
            }
          />
        </RowLayout>
        <FormControl error={state.errTnCs}>
          <SFormControlLabel
            control={
              <Checkbox
                id="checkbox-authority-and-direct-tnc"
                checked={state.directDebitTnCCheck}
                onChange={handleAuthorityAndDirectDebitTnCCheck}
                value="authority_direct_debit_check"
                color="primary"
              />
            }
            label={
              <div>
                <Typography display="inline">
                  I confirm I have authority over this bank account and I have
                  read and accept the{" "}
                </Typography>
                <Link
                  variant="body1"
                  display="inline"
                  href={`${process.env.REACT_APP_Z_WEBSITE_URL}power/dd`}
                  target="_blank"
                >
                  Direct Debit Terms and Conditions
                </Link>
              </div>
            }
          />
          <FormHelperText>
            {state.errTnCs ? "Please accept." : ""}
          </FormHelperText>
        </FormControl>
      </FormWrapper>
    </CollapseWrapped>
  );

  const directDebitJointCollapse = (
    <CollapseWrapped in={state.paymentType === PaymentTypes.DIRECT_DEBIT_JOINT}>
      <Typography variant="caption" component="p">
        You will need to complete a paper copy and send it in to us - note, this
        would only apply if you were signing up for a business or bank account
        that requires 2+ people to approve/sign. Your application can not be
        processed until you submit your Direct Debit form. If you want to fast
        track your application, you can always add a credit card instead.
      </Typography>
      <Button
        id="button-download-directdeb-form"
        variant="contained"
        href={process.env.REACT_APP_DIRECT_DEBIT_URL}
        target="_blank"
      >
        Download a Direct Debit form
      </Button>
    </CollapseWrapped>
  );

  const PAYMENT_OPTION_DETAILS: {
    label: string;
    value: PaymentTypes;
    isVisible: boolean;
    collapse: ReactNode;
  }[] = [
    {
      label: "Credit card or debit card",
      value: PaymentTypes.CREDIT_CARD,
      isVisible: state.paymentOptions.includes(PaymentTypes.CREDIT_CARD),
      collapse: creditCardCollapse,
    },
    {
      label: "Direct debit from my personal bank",
      value: PaymentTypes.DIRECT_DEBIT_PERSONAL,
      isVisible: state.paymentOptions.includes(
        PaymentTypes.DIRECT_DEBIT_PERSONAL,
      ),
      collapse: directDebitCollapse,
    },
    {
      label:
        "Direct debit from a bank account that requires 2+ people to approve/sign",
      value: PaymentTypes.DIRECT_DEBIT_JOINT,
      isVisible: state.paymentOptions.includes(PaymentTypes.DIRECT_DEBIT_JOINT),
      collapse: directDebitJointCollapse,
    },
  ];

  return (
    <Container component="main">
      <LoadingWrapper
        loading={
          registrationStatus === REQUEST_STATUS.Pending ||
          signUpUpdateStatus === REQUEST_STATUS.Pending
        }
      >
        <BackButton
          formStep={`${ROUTES.details}?step=property`}
          onNavigationBack={handleNavigationBack}
        />
        <Typography variant="body2" component="p">
          Step 3 of 3
        </Typography>
        <Typography variant="h3">Payment details</Typography>
        <FormControl>
          <Typography variant="h4" gutterBottom={true}>
            How would you like to pay your{" "}
            {selectedProduct === ProductName.Z_ELECTRIC_REWARDS
              ? "fortnightly"
              : "monthly"}{" "}
            bill?
          </Typography>
          <SingleSelectRadio
            options={PAYMENT_OPTION_DETAILS}
            onChangeOption={handlePaymentChange}
            selectedOptionValue={state.paymentType}
          />
        </FormControl>
        {state.errPaymentType && (
          <Typography
            variant="caption"
            color="error"
            id="text-error-preferred-payment-type"
          >
            {state.errPaymentType}
          </Typography>
        )}
        <Spacer />
        <TextInputShort
          id="promotional-code"
          label="Add promo code"
          inputProps={{ maxLength: 255 }}
          value={state.promoCode}
          onChange={handlePromoCodeChange}
          error={Boolean(state.errPromoCode)}
          helperText={
            !Boolean(state.errPromoCode) ? undefined : state.errPromoCode
          }
        />
        <Typography variant="h4">How did you hear about us?</Typography>
        <FormControl fullWidth sx={{ maxWidth: "500px" }}>
          <TextField
            select
            value={state.leadSource}
            label="Select one"
            id="lead-source-capture"
            onChange={(event) => handleLeadSourceChange(event.target.value)}
          >
            <MenuItem value={LeadSourceOptions.web_search}>Web Search</MenuItem>
            <MenuItem value={LeadSourceOptions.friend_or_family}>
              Friend Or Family
            </MenuItem>
            <MenuItem value={LeadSourceOptions.social_media}>
              Social Media
            </MenuItem>
            <MenuItem value={LeadSourceOptions.advertising}>
              Advertising
            </MenuItem>
            <MenuItem value={LeadSourceOptions.news_story}>News Story</MenuItem>
            <MenuItem value={LeadSourceOptions.comparison_site}>
              Comparison Site
            </MenuItem>
            <MenuItem value={LeadSourceOptions.im_a_previous_customer}>
              I'm a previous customer
            </MenuItem>
            <MenuItem value={LeadSourceOptions.the_spinoff}>
              The Spinoff
            </MenuItem>
            <MenuItem value={LeadSourceOptions.other}>Other</MenuItem>
          </TextField>
        </FormControl>
        <Typography variant="h4">Before we wrap up</Typography>
        <RowLayout>
          <FormControl>
            <SFormControlLabel
              control={
                <Checkbox
                  id="checkbox-marketing-comms"
                  checked={state.marketingAccepted}
                  onChange={handleMarketingCommsChange}
                  value="marketing_comms_checkbox"
                  color="primary"
                />
              }
              label="I agree to receive marketing and communications from Z Energy"
            />
          </FormControl>
        </RowLayout>
        <RowLayout>
          <FormControl
            error={state.errCreditCheck ? state.errCreditCheck : undefined}
          >
            <SFormControlLabel
              control={
                <Checkbox
                  id="checkbox-credit-check"
                  checked={state.creditCheck}
                  onChange={handleCreditCheckChange}
                  value="credit_check_checkbox"
                  color="primary"
                />
              }
              label={
                <>
                  <Typography variant="body1" display="inline" component="p">
                    I agree to you performing a credit check based on my details
                  </Typography>
                  <Typography variant="caption" component="p">
                    A credit check is required in order to process your
                    application.
                  </Typography>
                </>
              }
            />
            {state.errCreditCheck && (
              <FormHelperText>Please accept.</FormHelperText>
            )}
          </FormControl>
        </RowLayout>
        <RowLayout>
          <FormControl error={state.errZElecTnCs}>
            <SFormControlLabel
              control={
                <Checkbox
                  id="zelec-tnc-check"
                  checked={state.zElecTnCCheck}
                  onChange={handleZElecTnCChange}
                  value="zelec_tnc_checkbox"
                  color="primary"
                />
              }
              label={
                <>
                  <Typography variant="body1" display="inline" component="p">
                    I accept the Z{" "}
                  </Typography>
                  <Link
                    display="inline"
                    href={`${process.env.REACT_APP_Z_WEBSITE_URL}power/tc`}
                    target="_blank"
                  >
                    Terms and Conditions
                  </Link>{" "}
                  <Typography variant="body1" display="inline">
                    and{" "}
                  </Typography>
                  <Link
                    display="inline"
                    href={`${process.env.REACT_APP_Z_WEBSITE_URL}privacy/#privacy-policy`}
                    target="_blank"
                  >
                    Privacy Policy.
                  </Link>
                  <Typography variant="caption" component="p">
                    Please review our power plans’ terms and conditions
                    carefully - they form a legally binding contract, and it’s
                    really important to us that you understand what they cover.
                    In particular, we want you to know about how and why prices
                    change, information about outages, and what happens if
                    things go wrong.
                  </Typography>
                </>
              }
            />
            {state.errZElecTnCs && (
              <FormHelperText>Please accept.</FormHelperText>
            )}
          </FormControl>
        </RowLayout>
        {state.errSubmission && (
          <RowLayout>
            <ChatLink>
              <ErrorLink variant="body2" color="error">
                {state.errSubmission}
              </ErrorLink>
            </ChatLink>
          </RowLayout>
        )}
        <Button
          variant="contained"
          onClick={handleSendApplicationPress}
          id="button-send-application"
          buttonsize="large"
        >
          Send my Application
        </Button>
      </LoadingWrapper>
    </Container>
  );
};
